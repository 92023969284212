import React from 'react';
import AboutSection from '../components/AboutSection';

const About: React.FC = () => {
  return (
    <>
      <AboutSection />
    </>
  );
};

export default About;