import React from 'react';
import WorkSection from '../components/WorkSection';

const Work: React.FC = () => {
  return (
    <>
      <WorkSection />
    </>
  );
};

export default Work;