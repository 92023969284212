import React from 'react';
import ContactSection from '../components/ContactSection';

const Contact: React.FC = () => {
  return (
    <>
      <ContactSection />
    </>
  );
};

export default Contact;